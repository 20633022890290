/* --- Global --- */
import {useEffect, useState} from 'react';
import {useListVals} from 'react-firebase-hooks/database';

/* --- Local --- */
import {database} from '@settings/firebase';
import {UserFundAccount} from '@components';

/* --- Component --- */
const UserList = props => {
  /* --- Database : State --- */
  const [values, loading, error] = useListVals(
    database
      .ref('users')
      .orderByChild('funded')
      .equalTo(false)
      .limitToFirst(20),
  );

  /* --- Component : State --- */
  const [errorMessage, setErrorMessage] = useState();

  /* --- Component : Effects --- */
  useEffect(() => {}, [loading]);

  const [, set] = useState();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  return loading ? (
    <LoadingMessage />
  ) : (
    Array.isArray(values) && (
      <Atom.Flex sx={{flexWrap: 'wrap'}}>
        {values.reverse().map(user => (
          <UserCard
            {...user}
            sx={{
              my: 3,
              width: ['100%', '100%'],
            }}
          />
        ))}
      </Atom.Flex>
    )
  );
};

const LoadingMessage = props => {
  return (
    <Atom.Heading as="h5" sm>
      Loading Users...
    </Atom.Heading>
  );
};

const UserCard = props => {
  return (
    <Atom.Flex alignCenter card between sx={props.sx}>
      <Atom.Box>
        <Atom.Flex>
          <Atom.Image
            src={props.avatar}
            sx={{
              borderRadius: 9999,
              border: '1px solid #FFF',
              boxShadow: 0,
              width: 50,
            }}
          />
          <Atom.Box sx={{ml: 2}}>
            <Atom.Heading as="h5" lg>
              {props.display}
            </Atom.Heading>
            <Atom.Span>{props.email}</Atom.Span>
          </Atom.Box>
        </Atom.Flex>
      </Atom.Box>
      <Atom.Box>
        <Atom.Span>{props.address}</Atom.Span>
      </Atom.Box>
      <Atom.Box>
        <UserFundAccount address={props.address} uid={props.uid} />
      </Atom.Box>
    </Atom.Flex>
  );
};

export default UserList;
