const devEnvironment = {
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCn80bTVdynOTzZDKbNek_ot4np3c68uak',
    authDomain: 'ethquest-rapid.firebaseapp.com',
    databaseURL: 'https://ethquest-rapid.firebaseio.com',
    projectId: 'ethquest-rapid',
    storageBucket: 'ethquest-rapid.appspot.com',
    messagingSenderId: '862779401775',
    appId: '1:862779401775:web:9d982c68666904ac0048da',
  },
};
const prdEnvironment = {
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCn80bTVdynOTzZDKbNek_ot4np3c68uak',
    authDomain: 'ethquest-rapid.firebaseapp.com',
    databaseURL: 'https://ethquest-rapid.firebaseio.com',
    projectId: 'ethquest-rapid',
    storageBucket: 'ethquest-rapid.appspot.com',
    messagingSenderId: '862779401775',
    appId: '1:862779401775:web:9d982c68666904ac0048da',
  },
};

if (process.env.NODE_ENV === 'production') {
  module.exports = prdEnvironment;
} else {
  module.exports = devEnvironment;
}
