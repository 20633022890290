/* --- Global --- */
import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {withFirebase} from '@horizin/firebase-system';
import {withEthers} from '@ethers-react/system';
import {firebase} from '@settings/firebase';
/* --- Local --- */
// import {createPetition} from '@datastore/mutations';

/* --- Component --- */
const FormSignPetition = props => {
  // Providers
  const ethers = withEthers();
  const firebaseProvider = withFirebase();
  const {handleSubmit, register, errors} = useForm();
  // State
  const [status, setStatus] = useState(0);
  const [user, setUser] = useState({name: undefined});
  const [isReady, setIsReady] = useState(false);

  /* --- Form : EventHandler : Submit --- */
  const onSubmit = async values => {
    try {
      setStatus(1);
      const user = firebaseProvider.user;
      console.log(user);
      const ref = firebase.database().ref(`users/${user.uid}`);
      ref.update(values);
      console.log(ref);
    } catch (error) {
      console.log(error);
      setStatus(-1);
    }
  };

  useEffect(() => {
    setUser(firebaseProvider.user);
  }, [firebaseProvider.user]);

  useEffect(() => {
    if (firebaseProvider.user && ethers.address) {
      setIsReady(true);
    }
  }, [firebaseProvider.user, ethers.address]);

  /* --- Form : Component --- */
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
      {status === 1 ? (
        <SubmissionComplete />
      ) : (
        <Atom.Flex column sx={{flex: 1}}>
          <Molecule.Field
            name="name"
            placeholder="Name"
            defaultValue={user && user.displayName}
            register={register}
            errors={errors}
            sx={{
              border: 'none',
              borderLeft: '2px solid',
              borderColor: 'gray',
              mt: 2,
              boxShadow: 1,
              width: ['100%'],
              p: 3,
            }}
          />

          <Molecule.Field
            name="email"
            placeholder="Email"
            defaultValue={user && user.email}
            register={register}
            errors={errors}
            sx={{
              border: 'none',
              borderLeft: '2px solid',
              borderColor: 'gray',
              mt: 2,
              boxShadow: 1,
              width: ['100%'],
              p: 3,
            }}
          />

          <Molecule.Field
            name="address"
            placeholder="Address"
            defaultValue={ethers && ethers.address}
            register={register}
            errors={errors}
            sx={{
              border: 'none',
              borderLeft: '2px solid',
              borderColor: 'gray',
              mt: 2,
              boxShadow: 1,
              width: ['100%'],
              p: 3,
            }}
          />

          <Atom.Span>
            <Atom.Button
              // gradient="blue"
              direction={180}
              disabled={!isReady}
              md
              sx={
                isReady
                  ? {
                      bg: 'blue',
                      color: 'white',
                      height: 60,
                      width: '100%',
                      mt: 3,
                    }
                  : {
                      height: 60,
                      width: '100%',
                      mt: 3,
                    }
              }>
              <Atom.Span as="h3" xl>
                {!isReady ? (
                  <Atom.Span> Login to Claim</Atom.Span>
                ) : (
                  <Atom.Span>Claim FREE ΞTH</Atom.Span>
                )}
              </Atom.Span>
            </Atom.Button>
          </Atom.Span>
        </Atom.Flex>
      )}
    </form>
  );
};

const SubmissionComplete = props => {
  return (
    <Atom.Flex card center column>
      <Atom.Heading>Thank You!</Atom.Heading>
      <Atom.Paragraph>
        Verifying You're a Human and at EthDenver.
      </Atom.Paragraph>
    </Atom.Flex>
  );
};

export default FormSignPetition;
