/* --- Global --- */
import Modal from 'react-modal';
import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {hooks} from '@ethers-react/system';
import {withEthers, selectors, utils, ethers} from '@ethers-react/system';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

/* --- Modal : Component --- */
const FormClaimOverlay = ({subdomain, ...props}) => {
  const ethersProvider = withEthers();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  useEffect(() => {
    Modal.setAppElement('#___gatsby');
  }, []);
  return (
    <>
      <Atom.Button
        md
        // type="button"
        gradient="amin"
        direction={180}
        onClick={() => toggleOpen()}
        sx={styles.button}>
        Claim
      </Atom.Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOpen}
        style={customStyles}
        contentLabel="Claim ENS Sub-Domain">
        <Atom.Heading normal sx={{fontSize: [4, 4, 5]}}>
          Send Tip
        </Atom.Heading>
        <FormClaim subdomainDefault={subdomain} domainDefault="milliondevs" />
      </Modal>
    </>
  );
};

/* --- Form : Component --- */
const FormClaim = props => {
  const {handleSubmit, register, errors} = useForm();
  const transactionRequest = hooks.useWalletSendTransaction({});
  const contractSelector = selectors.useSelectContractByName(
    'RapidSubdomainRegistrarMeta',
  );

  const txAction = hooks.useWalletSendTransaction();

  /* --- Submit : Action --- */
  const onSubmit = async values => {
    let tx = {
      to: values.address,
      value: utils.parseEther(values.amount),
      data: '0x',
    };
    txAction.init(tx);
  };

  /* --- Transaction : Effect --- */
  // useEffect(() => {
  //   console.log(transactionRequest);
  // }, [transactionRequest]);

  return (
    <Atom.Box
      sx={{
        width: ['100%', 600, 600],
      }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
        <Molecule.Field
          name="address"
          label="Address"
          placeholder="milliondevs.eth"
          defaultValue={'0xDf665b527BE838B436AeEfACD6d076314391B03b'}
          register={register}
          errors={errors}
          sx={styles.field}
          sxWrapper={{
            flex: 1,
            width: '100%',
          }}
        />
        <Molecule.Field
          name="amount"
          placeholder="Amount"
          label="Amount"
          defaultValue={0}
          register={register}
          errors={errors}
          sx={styles.field}
          sxWrapper={{
            flex: 1,
          }}
        />

        <Atom.Button
          md
          gradient="amin"
          direction={180}
          sx={{mt: 3, height: 50, flex: 1, width: '100%'}}>
          Claim Domain
        </Atom.Button>
      </form>
    </Atom.Box>
  );
};

const styles = {
  field: {
    borderColor: 'gray',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: 0,
    flex: 3,
    height: 50,
    p: 10,
    width: '100%',
  },
  button: {
    height: 50,
    flex: 1,
    width: '100%',
  },
};

export default FormClaimOverlay;
