import {useEffect, useState} from 'react';
import {auth, firebase} from '@settings/firebase';
import {github} from '@settings/firebase.auth.providers.js';

import {withFirebase} from '@horizin/firebase-system';
console.log(github, auth, 'authauth');
/* --- GithubLogin : Component --- */
const GithubLogin = props => {
  const firebaseProvider = withFirebase();
  const [loginRequest, setLoginRequest] = useState(false);

  useEffect(() => {
    if (loginRequest) {
      try {
        firebase
          .auth()
          .signInWithPopup(github)
          .then(function(result) {
            var token = result.credential.accessToken;
            var user = result.user;
            console.log(user, firebaseProvider, 'user');
            firebaseProvider.userSet(user);
          })
          .catch(function(error) {
            console.log(error);
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [loginRequest]);
  return (
    <Atom.Button white rounded m1 onClick={setLoginRequest}>
      <Atom.Image
        src="https://img.favpng.com/4/20/21/github-computer-icons-icon-design-png-favpng-KaDTwuHWgG8D1qn47QW2hK7Gj.jpg"
        sx={{
          width: 20,
        }}
      />
      <Atom.Span sx={{ml: 2}}>Github</Atom.Span>
    </Atom.Button>
  );
};
export default GithubLogin;
