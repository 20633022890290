import {useState, useEffect} from 'react';
import {withEthers} from '@ethers-react/system';

/* --- FortmaticLogin: Component --- */
const FortmaticLogin = props => {
  const ethers = withEthers();
  const [enableRequest, setEnableRequest] = useState(false);

  useEffect(() => {}, [ethers.provider]);
  useEffect(() => {
    if (enableRequest && ethers.provider) {
      (async () => {
        const login = await ethers.provider.provider.enable();
        const signer = await ethers.provider.getSigner(login[0]);
        console.log(ethers, signer, 'signer');
        ethers.addressSet(login[0]);
      })();
    }
  }, [enableRequest, ethers.provider]);
  return (
    <Atom.Button white rounded purple m1 onClick={setEnableRequest}>
      <Atom.Image
        src="https://pbs.twimg.com/profile_images/1197964697141559296/qBu3h4jj_400x400.png"
        sx={{
          width: 20,
        }}
      />
      <Atom.Span sx={{ml: 2}}>Fortmatic</Atom.Span>
    </Atom.Button>
  );
};
export default FortmaticLogin;
