/* --- Global --- */
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

/* --- Local --- */
// import {createPetition} from '@datastore/mutations';

/* --- Component --- */
const FormSignPetition = props => {
  const {handleSubmit, register, errors} = useForm();
  const [status, setStatus] = useState(0);

  /* --- Form : EventHandler : Submit --- */
  const onSubmit = async values => {
    const petitionDetails = values;
    try {
      setStatus(1);
    } catch (error) {
      console.log(error);
      setStatus(-1);
    }
  };

  const placeholderField = `[
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "name",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "symbol",
          "type": "string"
        },
        {
          "internalType": "uint8",
          "name": "decimals",
          "type": "uint8"
        },
        {
          "internalType": "uint256",
          "name": "totalSupply",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    `;
  /* --- Form : Component --- */
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
      {status === 1 ? (
        <SubmissionComplete />
      ) : (
        <Atom.Flex column sx={{flex: 1}}>
          <Molecule.Field
            name="Smart Contract ABI"
            as="textarea"
            placeholder={placeholderField}
            register={register}
            errors={errors}
            sx={{
              border: 'none',
              boxShadow: 1,
              height: 160,
              width: ['100%'],
              p: 3,
            }}
          />

          <Atom.Span>
            <Atom.Button
              gradient="green"
              direction={180}
              md
              sx={{
                height: 60,
                width: '100%',
                mt: 3,
              }}>
              <Atom.Span as="h3" xl>
                Redeem Certificates
              </Atom.Span>
            </Atom.Button>
          </Atom.Span>
        </Atom.Flex>
      )}
    </form>
  );
};

const SubmissionComplete = props => {
  return (
    <Atom.Flex card center column>
      <Atom.Heading>Thank You!</Atom.Heading>
      <Atom.Paragraph>
        I will get back You as soon as possible. In the meantime be sure to
        checkout the "New Project To-Do List"
      </Atom.Paragraph>
      <Atom.Span sx={{color: 'blue', fontWeight: 700}}>
        <a href="https://twitter.com/kamescg" target="_blank">
          @EthQuest
        </a>
      </Atom.Span>
    </Atom.Flex>
  );
};

export default FormSignPetition;
