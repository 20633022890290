import {useState, useEffect} from 'react';
import {withEthers} from '@ethers-react/system';

/* --- FortmaticLogin: Component --- */
const FortmaticLogin = props => {
  const ethers = withEthers();
  const [enableRequest, SetEnableRequest] = useState(false);

  useEffect(() => {}, [ethers.provider]);
  useEffect(() => {
    (async () => {
      const login = await provider.provider.enable();
      const user = await fortmatic.user.getUser();
      const signer = await provider.getSigner(login[0]);
      console.log(signer);
      // const tx = await signer.sendTransaction({
      //   //   // From address will automatically be replaced by the address of current user
      //   // from: '0x5AdB8209b5276A23426994298FE9900644F57924',
      //   to: '0x5AdB8209b5276A23426994298FE9900644F57924',
      //   data: '0x',
      //   //   // value: utils.hexlify(utils.parseEther('0.1')),
      //   value: utils.parseEther('0.1'),
      // });
      const tx = await signer.signMessage('OneMillionDevelopers');
    })();
  }, []);
  return <Atom.Button>Fortmatic Login</Atom.Button>;
};
export default FortmaticLogin;
