import {useEffect, useState} from 'react';
/* --- Local --- */
import {database} from '@settings/firebase';
import {firebase} from '@settings/firebase';
/* --- UserFundAccount : Component --- */
const UserFundAccount = props => {
  const [fundingRequest, setFundRequest] = useState(false);

  const fundUserRequest = () => setFundRequest(true);

  useEffect(() => {
    if (fundingRequest) {
      console.log(props, 'props.address');
      const ref = firebase.database().ref(`requests`);
      ref.push({
        address: props.address,
        uid: props.uid,
        type: 'fund',
      });
    }
  }, [fundingRequest]);

  return (
    <Atom.Span onClick={fundUserRequest}>
      <Atom.Button blue sm m1>
        Fund Account
      </Atom.Button>
    </Atom.Span>
  );
};
export default UserFundAccount;
